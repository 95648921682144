import { Grid } from '@material-ui/core';

import PropTypes from 'prop-types';

import { Image } from '@/src/components/atoms';
import { CardBase, ContactForm } from '@/src/components/organisms';

const Referral = props => {
  const { formRef, className, ...rest } = props;

  return (
    <div className={className} data-aos="fade-up" {...rest} ref={formRef}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Image
            src="/images/illustrations/careers/hero.svg"
            alt="Quer vender online?"
            lazy={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardBase withShadow liftUp>
            <ContactForm />
          </CardBase>
        </Grid>
      </Grid>
    </div>
  );
};

Referral.propTypes = {
  formRef: PropTypes.any,
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Referral;
